<template>
  <v-container id="loan-disbursement" fluid tag="section">
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="blue"
          icon="mdi-chart-donut"
          title="Processed Loans (ITD)"
          value="937"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="#5f90ca"
          icon="mdi-chart-donut"
          title="Total Amount (ITD)"
          value="K76430.00"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-chart-donut"
          title="Avg Weekly (ITD)"
          value="K200"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="#4b96c3"
          icon="mdi-chart-donut"
          title="Avg Monthly (ITD)"
          value="K6000"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-row rows="12" align="end" justify="end"> </v-row>
      </v-col>

      <v-col cols="12" md="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-dark lightgray--text">
              Issued Loans History
              <button class="float-right">Download</button>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                color="lightgray"
              />
            </div>

            <div class="float-right white--text">
              <v-switch
                v-model="singleExpand"
                color="#ffffff"
                label="Single Expand"
                class="mt-2"
              ></v-switch>
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="id"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-expand
              class="elevation-1"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-spacer />
                  More info about {{ item.id }}
                  <v-spacer />
                  More info about {{ item.lname }}
                  <v-spacer>
                    More info about {{ item.fname }}
                    <v-spacer />
                    More info about {{ item.organization }}
                  </v-spacer>
                  <v-spacer />
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    search: "",
    sortBy: "id",
    sortDesc: true,
    expanded: [],
    singleExpand: false,
    headers: [
      {
        sortable: true,
        text: "Due Date",
        value: "dueDate",
        align: "left",
      },
      {
        sortable: true,
        text: "Issued Date",
        value: "date",
        align: "left",
      },
      {
        sortable: true,
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Phone",
        value: "phone",
      },
      {
        sortable: true,
        text: "Aging (Remaining Days)",
        value: "aging",
        align: "center",
      },
      {
        sortable: true,
        text: "Status",
        value: "status",
      },
      {
        sortable: true,
        text: "Amount (ZMW)",
        value: "amount",
        align: "right",
        class: "font-weight-black",
      },
      { text: "", value: "data-table-expand", align: "right" },
    ],
    items: [
      {
        id: 1,
        name: "Mwerenga Nicky",
        phone: "260974327259",
        amount: "K200",
        aging: "-2",
        status: "Ovedue",
        dueDate: "2020/12/23",
        date: "2020/12/23",
      },
      {
        id: 2,
        name: "Solomon Leonard",
        phone: "260950637780",
        amount: "K62",
        aging: "29",
        status: "Active",
        dueDate: "2020/03/23",
        date: "2020/02/23",
      },
      {
        id: 3,
        name: "Saunda Benard",
        phone: "26095929292",
        amount: "8932",
        aging: "K30",
        status: "Active",
        dueDate: "2021/04/28",
        date: "2021/03/28",
      },
      {
        id: 4,
        name: "Nyawela Patrick",
        phone: "26096728273",
        amount: "K1000",
        aging: "-2",
        status: "Ovedue",
        dueDate: "2021/03/08",
        date: "2021/02/08",
      },
      {
        id: 5,
        name: "Sekerere Ronny",
        phone: "26077826372",
        amount: "K700",
        aging: "-8",
        status: "Ovedue",
        dueDate: "2020/02/01",
        date: "2020/02/01",
      },
      {
        id: 6,
        name: "Solomon Leonard",
        phone: "26075102912",
        amount: "K12",
        aging: "0",
        status: "Settled",
        dueDate: "2021/04/14",
        date: "2021/03/14",
      },
    ],
  }),
  mounted() {
    document.title = 'Disbursed Loans | Kredwise'
  },
};
</script>

<style lang="scss" scoped></style>
